// index好評回饋
(() => {
  const ele = document.querySelector('.index-customer .mySwiper1');
  if (!ele) return;

  const swiper = new Swiper(ele, {
    slidesPerView: 2,
    spaceBetween: 0,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.index-customer .swiper-pagination',
    },
    breakpoints: {
      961: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    },
  });
})();

// menu開關
(() => {
  const menuOpenBtn = document.querySelector('.menu-box .menu-btn');
  const menuCloseBtn = document.querySelector('.menu-content .close-btn');
  const menuContent = document.querySelector('.menu-content');
  const menuItemBox = document.querySelector('.menu-content .item-box');
  const menuItemBoxA = menuItemBox.querySelectorAll('a');
  if (!menuOpenBtn) return;

  menuOpenBtn.addEventListener('click', () => {
    menuContent.classList.add('show');
    menuContent.classList.remove('hidden');
  });

  menuCloseBtn.addEventListener('click', () => {
    menuContent.classList.remove('show');
    menuContent.classList.add('hidden');
  });

  // 點擊項目連結關閉菜單
  menuItemBoxA.forEach((item) => {
    item.addEventListener('click', () => {
      menuContent.classList.remove('show');
      menuContent.classList.add('hidden');
    });
  });
})();

//  背景字水平橫移效果
(() => {
  gsap.utils.toArray('.js-scroll-x').forEach(function (e) {
    if (window.innerWidth > 1023) {
      gsap.fromTo(
        e,
        {
          x: 0,
        },
        {
          x: -250,
          delay: 0.2,
          scrollTrigger: {
            trigger: e,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 0.5,
          },
        }
      );
    }
  });
})();
